<template>
    <div>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" icon-prefix="al-icon" :title_icon="my_nav_bar_icon |filter_icon"></nav-bar-component>
        <van-grid clickable :column-num="2" :gutter="15">
            <van-grid-item
                v-for="v in tabbar_arr[active].children"
                :key="v.name"
                :text="v.name"
                icon-prefix="al-icon"
                :icon="v.icon | filter_icon"
                :badge="v.badge"
                :url="v.url"
                :to="v.to"
            />
        </van-grid>
        <tabbar-component :tabbar_arr="tabbar_arr" @clk="tabbar_clk"></tabbar-component>
    </div>
</template>

<script>
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import TabbarComponent from '@/components/common/TabbarComponent.vue'

import { my_information_request,my_notice_num_request } from '@/network/user'

export default {
    name: 'IndexView',
    data() {
        return {
            active: this.$store.state.active,
            my_nav_bar_name: this.$store.state.my_nav_bar_name,
            my_nav_bar_icon: this.$store.state.my_nav_bar_icon,
            tabbar_arr: [
                {
                    name: '仓储',
                    icon: 'al-icon-cangku',
                    children: [
                        {
                            name: '客户费率管理',
                            icon: 'al-icon-feishuaiguanli',
                            badge: '',
                            url: '',
                            to: '/ClientStockRateIndex'
                        },{
                            name: '产品管理',
                            icon: 'al-icon-chanpinku',
                            badge: '',
                            url: '',
                            to: '/ProductIndex'
                        },
                        {
                            name: '仓库管理',
                            icon: 'al-icon-cangkuguanli',
                            badge: '',
                            url: '',
                            to: '/StoreroomIndex'
                        },{
                            name: '库位管理',
                            icon: 'al-icon-kuwei',
                            badge: '',
                            url: '',
                            to: '/LocationIndex'
                        },{
                            name: '库存明细',
                            icon: 'al-icon-wuliaokucunguankong',
                            badge: '',
                            url: '',
                            to: '/StockIndex'
                        },{
                            name: '库存统计',
                            icon: 'al-icon-navicon-kcfltj',
                            badge: '',
                            url: '',
                            to: '/StockCount'
                        },{
                            name: '装卸排队',
                            icon: 'al-icon-bhjpaidui',
                            badge: '',
                            url: '',
                            to: '/CarDriverAppointStaffIndex'
                        }
                    ]
                },
                {
                    name: '考勤',
                    icon: 'al-icon-kaoqin',
                    children: [
                        {
                            name: '休假管理',
                            icon: 'al-icon-qingjia',
                            badge: '',
                            url: '',
                            to: '/VacationIndex'
                        },
                        {
                            name: '外出管理',
                            icon: 'al-icon-waichushenqing',
                            badge: '',
                            url: '',
                            to: '/GoOutIndex'
                        },
                        {
                            name: '加班管理',
                            icon: 'al-icon-jiaban',
                            badge: '',
                            url: '',
                            to: '/OverTimeIndex'
                        },
                        {
                            name: '补签管理',
                            icon: 'al-icon-qiandaodaka',
                            badge: '',
                            url: '',
                            to: '/SupplementClockInIndex'
                        }
                    ]
                },
                {
                    name: 'ＯＡ',
                    icon: 'al-icon-OAxitong',
                    children: [
                        {
                            name: '银行卡管理',
                            icon: 'al-icon-yinxingqia',
                            badge: '',
                            url: '',
                            to: '/BankCardIndex'
                        },
                        {
                            name: '采购单管理',
                            icon: 'al-icon-icon-cart',
                            badge: '',
                            url: '',
                            to: '/PurchaseIndex'
                        },
                        {
                            name: '招待单管理',
                            icon: 'al-icon-yewuzhaodai',
                            badge: '',
                            url: '',
                            to: '/EntertainIndex'
                        },
                        {
                            name: '差旅管理',
                            icon: 'al-icon-chuchai',
                            badge: '',
                            url: '',
                            to: '/TravelIndex'
                        },
                        {
                            name: '备用金管理',
                            icon: 'al-icon-jiekuanshenqing',
                            badge: '',
                            url: '',
                            to: '/PettyIndex'
                        },
                        {
                            name: '报销单管理',
                            icon: 'al-icon-shenqingbaoxiao',
                            badge: '',
                            url: '',
                            to: '/ReimbursementIndex'
                        },
                        {
                            name: '印章使用申请管理',
                            icon: 'al-icon-yinzhang',
                            badge: '',
                            url: '',
                            to: '/CurrencyIndex'
                        },
                        {
                            name: '公务车申请管理',
                            icon: 'al-icon-car-fill',
                            badge: '',
                            url: '',
                            to: '/CarUseIndex'
                        }
                        // {
                        //     name: '招待客户管理',
                        //     icon: 'user-o',
                        //     badge: '',
                        //     url: '',
                        //     to: '/EntertainClientIndex'
                        // },
                        // {
                        //     name: '差旅目的地管理',
                        //     icon: 'location-o',
                        //     badge: '',
                        //     url: '',
                        //     to: '/TravelAddressIndex'
                        // },
                        // {
                        //     name: '报销类目管理',
                        //     icon: 'notes-o',
                        //     badge: '',
                        //     url: '',
                        //     to: '/ReimbursementCategoryIndex'
                        // },
                    ]
                },
                {
                    name: '商务',
                    icon: 'al-icon-hezuo',
                    children: [
                        // {
                        //     name: '合作企业管理',
                        //     icon: 'al-icon-kehu',
                        //     badge: '',
                        //     url: '',
                        //     to: '/ClientIndex'
                        // },
                        {
                            name: '合作企业申请管理',
                            icon: 'al-icon-kehu',
                            badge: '',
                            url: '',
                            to: '/CooperativeEnterpriseIndex'
                        },
                        {
                            name: '合同管理',
                            icon: 'al-icon-laodonghetong',
                            badge: '',
                            url: '',
                            to: '/ContractIndex'
                        },
                        {
                            name: '业务付款管理',
                            icon: 'al-icon-webicon15',
                            badge: '',
                            url: '',
                            to: '/BusinessPaymentIndex'
                        }
                    ]
                },
                {
                    name: '我的',
                    icon: 'al-icon-icon_wode',
                    children: [
                        {
                            name: '个人信息',
                            icon: 'al-icon-gerenxinxi',
                            badge: '',
                            url: '',
                            to: '/UserInformation'
                        },
                        {
                            name: '修改头像',
                            icon: 'al-icon-touxiang',
                            badge: '',
                            url: '',
                            to: '/UserAvatar'
                        },
                        {
                            name: '修改密码',
                            icon: 'al-icon-mima',
                            badge: '',
                            url: '',
                            to: '/UserPassWord'
                        },
                        {
                            name: '修改邮箱',
                            icon: 'al-icon-youxiang',
                            badge: '',
                            url: '',
                            to: '/UserEmail'
                        },
                        {
                            name: '修改手机号',
                            icon: 'al-icon-shouji',
                            badge: '',
                            url: '',
                            to: '/UserPhone'
                        },
                        {
                            name: '通知设置',
                            icon: 'al-icon-tongzhi',   
                            badge: '',
                            url: '',
                            to: '/UserNotice'
                        },
                        {
                            name: '我的申请',
                            icon: 'al-icon-ceshishenqing',   
                            badge: '',
                            url: '',
                            to: '/UserApply'
                        },
                        {
                            name: '我的审批',
                            icon: 'al-icon-tianshenpi',   
                            badge: '',
                            url: '',
                            to: '/UserExamine'
                        },
                        {
                            name: '我的抄送',
                            icon: 'al-icon-chaosonggeiwo',   
                            badge: '',
                            url: '',
                            to: '/UserCc'
                        }
                    ]
                },
            ]
        }
    },
    computed: {},
    methods: {
        tabbar_clk(a) {
            this.active = a
            this.my_nav_bar_name = this.tabbar_arr[a].name,
            this.my_nav_bar_icon = this.tabbar_arr[a].icon
            let obj = {}
            obj = {
                active: a,
                my_nav_bar_name: this.tabbar_arr[a].name,
                my_nav_bar_icon: this.tabbar_arr[a].icon
            }
            this.$store.commit('edit_active',obj)
        },
        get_my_information() {
            my_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.$store.commit('edit_user', s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        get_notice_num() {
            my_notice_num_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.tabbar_arr[4].children[6].badge = s.result.my_apply
                        this.tabbar_arr[4].children[7].badge = s.result.my_examine
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        }
    },
    filters: {
        filter_icon(v) {
            const i = v.split('al-icon-')
            return i[1]
        }
    },
    props: {
        nav_bar: {
            type: Object,
            default() {
                return {
                    left: false,
                    right: {
                        type: '',
                        name: ''
                    },
                    fixed: false,   //固定
                    placeholder: false  //等高占位
                }
            }
        },
        nav_bar_name: {
            type: String,
            default() {
                return ''
            }
        },
        nav_bar_icon: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created() {
        this.get_my_information()
        this.get_notice_num()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        NavBarComponent,
        TabbarComponent
    },
    watch: {
        nav_bar_name(newVal) {
            this.my_nav_bar_name = newVal
        },
        nav_bar_icon(newVal) {
            this.my_nav_bar_icon = newVal
        }
    },
}
</script>

<style>
.van-grid {
    margin-top: 0.3rem;
}
.van-grid-item__content {
    box-shadow:0 0 10px #C4E1FF;
    border-radius:5%
}
.van-grid-item__icon {
    color: #B15BFF
}
</style>